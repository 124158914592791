import ILog from "app/_lib/global/Log";
import { apiNext } from "app/_services/redux/api/apiNext";
import { apiService } from "app/_services/redux/api/apiService";
import { directoryConfigurationActions } from "app/_services/redux/slices/directoryConfiguration";
import { directoryItemActions } from "app/_services/redux/slices/directoryItem";
import { directoryItemLabelActions } from "app/_services/redux/slices/directoryItemLabel";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import signOut from "../_actions/signOut";

export default function useLogout() {
   const router = useRouter();
   const pathname = usePathname();
   const searchParams = useSearchParams();
   const fullSearchParamsString = searchParams.toString();
   // const callbackUrl = searchParams?.get("callback");
   // // const [signOut] = apiAuthenticatedSession.useSignOutMutation({ fixedCacheKey: `sign-out-cache` });
   const [action, { isSuccess }] = apiNext.useActionMutation({ fixedCacheKey: `sign-out-cache` });
   const dispatch = useDispatch();

   const logOut = useCallback(() => {
      dispatch({ type: "app/setAllMenus", payload: {} });
      dispatch(directoryConfigurationActions.removeAllDirectoryConfigurations());
      dispatch(directoryItemActions.removeAllDirectoryItems());
      dispatch(directoryItemLabelActions.removeAllDirectoryItemLabels());
      dispatch({
         type: "appState/setAvatar",
         payload: null
      });

      dispatch(apiService.util.resetApiState());

      sessionStorage.clear();
      action(async () => {
         return await signOut({ redirect: `sign-in?callback=${pathname}?${fullSearchParamsString}` });
      });
      // router.push(`/sign-in?callback=${pathname}?${fullSearchParamsString}`);
   }, [pathname, fullSearchParamsString]);

   useEffect(() => {
      if (isSuccess) {
         ILog.v("useLogout", { pathname, fullSearchParamsString });
         router.push(`/sign-in?callback=${pathname}?${fullSearchParamsString}`);
      }
   }, [isSuccess]);
   return logOut;
}
