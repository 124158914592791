import { DateTime } from "luxon";
export default function calculateDateTime(timestamp: number, includeHourAndMinutes?: boolean): string {
   let readableDate = "";
   if (typeof timestamp !== "number") throw new Error("timestamp must be a number, got " + typeof timestamp)

   const date = DateTime.fromSeconds(timestamp);

   readableDate = date.toLocaleString({
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: includeHourAndMinutes ?"numeric" : undefined,
      minute: includeHourAndMinutes ? "numeric" : undefined
   });

   return readableDate;
}
