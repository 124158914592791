import { useCallback, useRef } from "react";

// https://medium.com/swlh/using-a-debounced-callback-in-react-ade57d31ca6b
export function useDebouncedCallback(func: any, wait: number) {
   // Use a ref to store the timeout between renders
   // and prevent changes to it from causing re-renders
   const timeout = useRef<NodeJS.Timeout | undefined>();

   return useCallback(
      (...args: any[]) => {
         const later = () => {
            clearTimeout(timeout.current);
            func(...args);
         };

         clearTimeout(timeout.current);
         timeout.current = setTimeout(later, wait);
      },
      [func, wait]
   );
}
