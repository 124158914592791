"use client";
import useDateTime from "app/_hooks/useDateTime";
import { PublicEnv } from "app/_lib/global/PublicEnv";
import { useRouter, useSearchParams } from "next/navigation";
import { useContext, useRef } from "react";
import ButtonAuthentication from "../_components/ButtonAuthentication";

import Footer from "app/(platform)/_components/Footer";

import { apiDirectusUsers } from "app/(platform)/@search/_api/directusUsers";
import { Body } from "app/(platform)/_components/Body";
import cleanupDirectoryState from "app/(platform)/profile/preferences/_helpers/cleanupDirectoryState";
import { defaultView } from "app/(platform)/profile/preferences/constants/defaultView";
import Header from "app/_components_v2/data-display/Header";
import Alert from "app/_components_v2/feedback/Alert";
import LoadingSquares from "app/_components_v2/feedback/loading/LoadingSquares";
import LeanInput from "app/_components_v2/inputs/LeanInput";
import _Button from "app/_components_v2/inputs/_Button";
import _Stack from "app/_components_v2/layout/_Stack";
import Info from "app/_components_v2/todo/Info";
import { AppContext } from "app/_contexts/AppContext";
import { useAppStore } from "app/_contexts/ReduxProvider";
import ILog from "app/_lib/global/Log";
import { apiNext } from "app/_services/redux/api/apiNext";
import { directoryConfigurationActions } from "app/_services/redux/slices/directoryConfiguration";
import { directoryItemActions } from "app/_services/redux/slices/directoryItem";
import { directoryItemLabelActions } from "app/_services/redux/slices/directoryItemLabel";
import { M } from "app/_types/Schema";
import signInCookie from "../_actions/signInCookie";
import { AuthStatusContext } from "../_contexts/AuthenticatedSessionProvider";

interface SignInProps {
   callbackUrlOverride?: string;
}
export default function SignIn({}: SignInProps) {
   const { status } = useContext(AuthStatusContext);
   const { migrationActive, migrationMessage } = useContext(AppContext);
   const params = useSearchParams();
   const formRef = useRef<HTMLFormElement | null>(null);
   const submitRef = useRef<HTMLButtonElement | null>(null);
   const error = params.get("error");
   const maintenance = params.get("maintenance");
   const callbackUrl = params.get("callback") || `dashboard`;
   const [action, { isError, isLoading, isUninitialized, isSuccess, data, reset }] = apiNext.useActionMutation({ fixedCacheKey: `sign-in-cache` });
   const router = useRouter();
   const store = useAppStore();
   ILog.v("SignInPage", { status, error, maintenance, callbackUrl, isError, isLoading, isUninitialized, isSuccess, data });

   const { humanReadableDate: maintenanceDate } = useDateTime(Number(PublicEnv.MigrationTimestamp));

   return (
      <Body>
         <_Stack alignment={"center"} gap={"lg"}>
            <title>Login / Sign Up</title>

            {error && (
               <Alert testId={`signin-alert`} type="error">
                  {error}
               </Alert>
            )}
            {maintenance || (error === "maintenance" && maintenanceDate && <Alert testId={`scheduled-maintenance`} type="error">{`Scheduled maintenance in progress until ${maintenanceDate}`}</Alert>)}

            <form
               ref={formRef}
               className=""
               onSubmit={async (e) => {
                  e.preventDefault();
                  const formData = new FormData(e.currentTarget);
                  const email = formData.get("email") as string;
                  const password = formData.get("password") as string;
                  if (!email || !password) throw new Error("Email and password are required.");
                  ILog.v("SignIn.tsx", { email, password, callbackUrl });
                  store.dispatch(directoryConfigurationActions.removeAllDirectoryConfigurations());
                  store.dispatch(directoryItemActions.removeAllDirectoryItems());
                  store.dispatch(directoryItemLabelActions.removeAllDirectoryItemLabels());

                  action(async () => {
                     const res = await signInCookie({ email, password, redirect: callbackUrl });

                     if (res?.error) {
                        router.push(`/sign-in?callback=${callbackUrl}&error=${res.error}`);
                     }
                     const user = await store
                        .dispatch(apiDirectusUsers.endpoints.getOneDirectusUsers.initiate({ token: res.data?.authenticated.access_token, id: res.data?.authenticated.user.id, role: undefined }))
                        .unwrap();

                     let configs: M.DirectoryConfiguration[] = [];
                     let items: M.DirectoryItem[] = [];
                     let labels: M.DirectoryItemLabel[] = [];

                     if (user?.directus_users_directory_builder) {
                        configs = [...user?.directus_users_directory_builder.configs, ...defaultView?.configs!];
                        items = [...user?.directus_users_directory_builder.items, ...defaultView?.items!];
                        labels = [...user?.directus_users_directory_builder.labels, ...defaultView?.labels!];
                     } else {
                        configs = [...defaultView?.configs!];
                        items = [...defaultView?.items!];
                        labels = [...defaultView?.labels!];
                     }
                     ILog.v("SignIn.tsx directoryConfiguration", { defaultView });

                     store.dispatch(directoryConfigurationActions.setDirectoryConfigurations(configs));
                     store.dispatch(directoryItemActions.setDirectoryItems(items));
                     store.dispatch(directoryItemLabelActions.setDirectoryItemLabels(labels));
                     store.dispatch(directoryConfigurationActions.setSelectedDirectoryConfigurationName("Default"));
                     store.dispatch({
                        type: "app/setAllMenus",
                        payload: {
                           leftNavOpen: true
                        }
                     });
                     setTimeout(() => {
                        cleanupDirectoryState({ store });
                        cleanupDirectoryState({ store });
                        cleanupDirectoryState({ store });
                     }, 50);
                     router.push(`${callbackUrl || "/dashboard"}`, {});
                     return res;
                  });
               }}
               aria-label="Login Form"
            >
               <_Stack>
                  <Header label="Potluck Action Login" level="h1" />
                  <input id="signin-csrftoken-input" data-testid="signin-csrftoken-input" name="csrfToken" type="hidden" />

                  <LeanInput.Text
                     label="Email"
                     testId={"signin-email-input"}
                     required
                     autoComplete="email"
                     name="email"
                     type="email"
                     onBlur={(e) => {
                        // formRef.current?.dispatchEvent(new Event("submit"));
                        // formRef.current?.submit();
                        submitRef.current?.click();
                     }}
                  />
                  <LeanInput.Text
                     label="Password"
                     testId={"signin-password-input"}
                     required
                     autoComplete="current-password"
                     name="password"
                     type="password"
                     onBlur={(e) => {
                        // formRef.current?.dispatchEvent(new Event("submit"));
                        // formRef.current?.submit();
                        submitRef.current?.click();
                     }}
                  />
                  {migrationMessage && migrationActive && <Info message={migrationMessage} />}
                  {isLoading ? (
                     <LoadingSquares />
                  ) : (
                     <_Button
                        // className="p-3 rounded-lg w-fit place-self-center bg-slate-300 hover:underline hover:shadow-md "
                        ref={submitRef}
                        type="submit"
                        aria-label="Login Button"
                        testId="signin-submit-button"
                        variant="primary"
                        size={"lg"}
                        onButtonClick={undefined}
                        href={undefined}
                        disabled={migrationActive}
                     >{`Login`}</_Button>
                  )}
                  <_Stack flexBehavior={"row"} alignment={"center"} gap={"md"}>
                     <ButtonAuthentication.SignUp />
                     <ButtonAuthentication.ResetPassword />
                  </_Stack>
               </_Stack>
            </form>
            <Footer />
         </_Stack>
      </Body>
   );
}
