// Alert.tsx
import { cva } from "class-variance-authority";
import React from "react";

interface AlertProps {
   type: "success" | "warning" | "error" | "info";
   children: React.ReactNode;
   className?: string;
   onClose?: () => void;
   testId: string;
}

const alertClasses = cva("p-4 mb-4 rounded-lg flex items-center justify-between", {
   variants: {
      type: {
         success: "bg-green-300 text-green-800",
         warning: "bg-amber-300 text-amber-800",
         error: "text-red-300 underline decoration-red-300 ",
         info: "bg-blue-300 text-blue-800"
      }
   }
});

const Alert: React.FC<AlertProps> = ({ testId, type, children, className, onClose }) => {
   const combinedClasses = alertClasses({ type }) + ` ${className || ""}`;

   return (
      <div data-testid={`${testId}`} className={combinedClasses} role="alert">
         <span>{children}</span>
         {onClose && (
            <button
               data-testid={`${testId}-alert-close-button`}
               onMouseDown={onClose}
               className="ml-4 text-current focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-current"
            >
               <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.029a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.697z" />
               </svg>
            </button>
         )}
      </div>
   );
};

export default Alert;
