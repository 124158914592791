import { Alignment, Placement, Strategy, autoPlacement, safePolygon, useFloating, useHover, useInteractions, useTransitionStyles } from "@floating-ui/react";
import ILog from "app/_lib/global/Log";
import { VariantProps, cva } from "class-variance-authority";
import React from "react";

export type FloatingNavUseFloatingProps = {
   placement?: Placement;
   strategy?: Strategy;
   allowedPlacements?: Placement[];
   alignment?: Alignment;
};

type FloatingNavProps = {
   referenceChild: React.ReactNode;
   floatingChild: ({ setHovered }: { setHovered: React.Dispatch<React.SetStateAction<boolean>> }) => React.ReactNode;
   className?: string;
   testId?: string;
   referenceId?: string;
   floatingId?: string;
   referenceClasses?: string;
   lockHovered?: boolean;
} & FloatingNavUseFloatingProps &
   React.DetailsHTMLAttributes<HTMLDivElement> &
   VariantProps<typeof classes>;

const classes = cva("", {
   variants: {
      labelBehavior: {
         primary: "flex flex-col gap-3 bg-slate-50 p-4 rounded-md shadow-md text-nowrap h-fit w-fit place-self-center",
         leftDrawer: "mr-6",
         rightDrawer: "ml-6"
      },
      referenceBehavior: {
         primary: "w-fit overflow-clip h-fit flex",
         secondary: ""
      },
      referenceMargin: {
         none: "",
         4: "mt-3"
      },
      zPosition: {
         primary: "z-50",
         secondary: "z-0"
      }
   },
   defaultVariants: {}
});

export function FloatingNav({
   referenceChild,
   floatingChild,
   className = "",
   testId,
   labelBehavior = "primary",
   referenceBehavior = "primary",
   referenceMargin = "none",
   placement = "top-start",
   strategy = "absolute",
   allowedPlacements = ["right-start"],
   alignment = "start",
   floatingId = "floating-id",
   referenceId = "reference-id",
   zPosition = "primary",
   referenceClasses = "",
   lockHovered = false
}: FloatingNavProps) {
   // const {} = React.useContext(FloatingNavContext);
   const [hovered, setHovered] = React.useState(false);
   const { context, refs, floatingStyles, update } = useFloating({
      placement,
      strategy,
      middleware: [
         autoPlacement({
            alignment,
            crossAxis: true,
            autoAlignment: true,
            allowedPlacements
         })
         // offset(({ rects }) => {
         //    // return -rects.floating.height / 2 - rects.floating.width / 2;
         //    return {
         //       crossAxis: rects.floating.width,
         //       mainAxis: -rects.floating.height
         //       // crossAxis: rects.floating.height / 2,
         //       // mainAxis: -rects.floating.width / 2
         //    };
         // })
      ]
   });

   const { isMounted, styles } = useTransitionStyles(context);
   const hover = useHover(context, { handleClose: safePolygon({ requireIntent: false }) });

   const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

   ILog.v("FloatingNav", { hover });
   // const labelInput = bool ? labelFocused : labelBehavior
   const finalHovered = lockHovered || hovered;

   const labelClasses = classes({ labelBehavior, zPosition }) + ` ${className} `;
   const referenceStyles = classes({ referenceBehavior, referenceMargin }) + ` ${className} ${finalHovered ? "" : ""}` + referenceClasses;
   update();
   return (
      <React.Fragment>
         {finalHovered && (
            <div
               id={floatingId}
               ref={refs.setFloating}
               className={labelClasses}
               // style={{ ...floatingStyles, ...styles }}
               style={floatingStyles}
               {...getFloatingProps({
                  onMouseOver: () => {
                     setHovered(true);
                  },
                  onMouseLeave: () => {
                     setHovered(false);
                  },
                  // onMouseOut: () => {
                  //    setHovered(false);
                  // }
                  onBlur: (e) => {
                     e.preventDefault();
                     // If the target is a child of this element, return;

                     ILog.v("FloatingNav_onBlur", {
                        ref: refs.domReference,
                        target: e.target,
                        currentTarget: e.currentTarget,
                        relatedTarget: e.relatedTarget,
                        e
                     });
                     if (!!e.currentTarget && !!e.target && !!e.relatedTarget) {
                        //https://stackoverflow.com/questions/56536191/how-do-i-check-if-my-event-target-is-typeof-html-input-element-in-react
                        if (e.currentTarget instanceof HTMLElement && e.target instanceof HTMLElement && e.relatedTarget instanceof HTMLElement) {
                           if (e.currentTarget.contains(e.relatedTarget)) return;
                           if (e.currentTarget.contains(e.target)) return;
                           if (e.target.closest(".MuiPopover-root")) return;
                        }
                        // https://stackoverflow.com/questions/51846839/failed-to-execute-contains-on-node-parameter-1-is-not-of-type-node
                        // if (e.currentTarget.classList.contains(e.relatedTarget.classList[0])) return;
                        // if (e.currentTarget.classList.contains(e.target.classList[0])) return;
                        // if (e.target.classList.contains(".MuiPopover-root")) return;
                     }

                     setHovered(false);
                  }
               })}
            >
               {floatingChild({ setHovered })}
            </div>
         )}
         {referenceChild && (
            <div
               id={referenceId}
               ref={refs.setReference}
               data-testid={testId}
               className={referenceStyles}
               {...getReferenceProps({
                  onMouseOver: () => {
                     setHovered(true);
                  },
                  onMouseLeave: () => {
                     setHovered(false);
                  }
                  // onBlur: (e) => {
                  //    setHovered(false);
                  // }
               })}
            >
               {referenceChild}
            </div>
         )}
      </React.Fragment>
   );
}
