import Image from "next/image";
import Logo from "./Isotype_Black.svg";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";

export default function Flag(props: { flag?: boolean }) {
   const { flag } = props;

   return (
      <_DivUNSAFE className="flex flex-row flex-nowrap gap-4">
         {flag && <Image src={Logo} alt="" className="h-16 w-16 place-self-center" />}
         <_DivUNSAFE className="flex flex-col place-self-center">
            <p className="text-xl font-bold ">Potluck Action</p>
            <p className="-mt-1.5 text-xl font-bold">Empathy Organized ®</p>
         </_DivUNSAFE>
      </_DivUNSAFE>
   );
}
