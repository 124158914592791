import ILog from "app/_lib/global/Log";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCallback } from "react";

export default function useRedirectToSignInPage() {
   const router = useRouter();
   const pathname = usePathname();
   const searchParams = useSearchParams();
   const fullSearchParamsString = searchParams.toString();
   const callbackUrl = searchParams?.get("callback");
   const redirectToSignInPage = useCallback(
      (/* params?: { redirect?: boolean; email?: string; password?: string; callbackUrl?: string } */) => {
         //  if (params?.email && params?.password) {
         //  }
         ILog.v("useRedirectToSignInPage", { pathname, fullSearchParamsString });
         router.push(`/sign-in?callback=${pathname || "/dashboard"}?${fullSearchParamsString}`);
      },
      [pathname, fullSearchParamsString]
   );

   return redirectToSignInPage;
}
