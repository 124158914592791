"use client";
import { createContext } from "react";

type BodySizeContextType = {
   bodyWidth: number | undefined;
   bodyHeight: number | undefined;
   windowWidth: number | undefined;
   windowHeight: number | undefined;
};
export const BodySizeContext = createContext<BodySizeContextType>({
   bodyWidth: undefined,
   bodyHeight: undefined,
   windowWidth: undefined,
   windowHeight: undefined
});
