"use client";

import LoadingSquares from "app/_components_v2/feedback/loading/LoadingSquares";
import _Button from "app/_components_v2/inputs/_Button";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import { MouseEventHandler, useContext } from "react";
import { AuthStatusContext } from "../_contexts/AuthenticatedSessionProvider";
import useLogout from "../_hooks/useLogout";
import useRedirectToSignInPage from "../_hooks/useRedirectToSignInPage";

function ButtonAuthentication(props: { cta?: string }) {
   const { cta = "Login / Sign Up" } = props;
   const logIn = useRedirectToSignInPage();
   const logOut = useLogout();
   const { status, isAuthenticated } = useContext(AuthStatusContext);

   return (
      <_DivUNSAFE className="flex flex-row items-center cursor-pointer flex-nowrap whitespace-nowrap hover:text-slate-900">
         {isAuthenticated && <ButtonAuthentication.SignOut handleSignout={logOut} />}
         {status === "loading" && <LoadingSquares />}
         {status === "unauthenticated" && (
            <_Button
               variant="primary"
               margin={"none"}
               testId="signin-signup-button"
               onButtonClick={() => {
                  logIn();
               }}
            >
               {cta}
            </_Button>
         )}
      </_DivUNSAFE>
   );
}

function SignIn() {
   return (
      <_Button variant="primary" testId="sign-in-button" size="lg" href="/sign-in">
         Login
      </_Button>
   );
}

function SignUp() {
   return (
      <_Button variant="primary" testId="sign-up-button" size="lg" href="/sign-up">
         Sign Up
      </_Button>
   );
}

function SignOut(props: { handleSignout: MouseEventHandler<HTMLButtonElement> }) {
   const { handleSignout } = props;
   return (
      <_Button variant="primary" rounded={true} testId="sign-out-button" size="lg" onButtonClick={handleSignout}>
         Sign Out
      </_Button>
   );
}

function ResetPassword() {
   return (
      <_Button variant="primary" testId="reset-password-button" size="lg" href="/reset-password">
         {`Reset Password`}
      </_Button>
   );
}

ButtonAuthentication.SignIn = SignIn;
ButtonAuthentication.SignUp = SignUp;
ButtonAuthentication.SignOut = SignOut;
ButtonAuthentication.ResetPassword = ResetPassword;

export default ButtonAuthentication;
