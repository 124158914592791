import { CampaignRoleTypes } from "app/_types/Schema";

export const Roles = {
   host: "Meeting Manager",
   campaign_manager: "Workspace Manager",
   owner: "Owner",
   connection: "Workspace Contributor",
   banned: "Bannned",
   public: "Public",
   authenticated: "Authenticated",
   dashboard_control_manager: "Dashboard Manager",
   qualification_set_control_manager: "Skillset Manager",
   todo_attempt_contributor: "Attempt Contributor",
   todo_attempt_manager: "Attempt Manager",
   todo_control_manager: "Team Manager",

   activity_control_manager: "Team Manager",
   activity_attempt_manager: "Attempt Manager",
   activity_attempt_contributor: "Attempt Contributor"
};

export const cduRoles: CampaignRoleTypes[] = ["connection", "host", "campaign_manager", "owner"];
