import { M } from "app/_types/Schema";

export const defaultView: M.DirectusUsers["directus_users_directory_builder"] = {
   configs: [{ name: "Default", items: ["f0d54074-496f-4783-ae30-101e35612e94"] }],
   items: [
      {
         id: "0f66ceef-4b9b-4513-9111-509467670fce",
         parentItem: "f0d54074-496f-4783-ae30-101e35612e94",
         collection: "campaign_edition",
         directoryItemLabels: ["369df7bd-605b-4753-8d43-57966c0a3e49"],
         items: ["2921a5e7-ebaf-462f-9b48-d3afe24c3958"],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         parentConfig: "Default"
      },
      {
         id: "2921a5e7-ebaf-462f-9b48-d3afe24c3958",
         parentItem: "0f66ceef-4b9b-4513-9111-509467670fce",
         collection: "campaign_edition",
         query: { group: "or", rules: [] },
         directoryItemLabels: ["7c71799d-9319-4a0f-b60c-bdf7b62ed522"],
         items: [],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         parentConfig: "Default"
      },
      {
         id: "70e1a935-ac8b-4f5e-9f95-c42ca6222761",
         parentItem: "f0d54074-496f-4783-ae30-101e35612e94",
         collection: "meeting",
         directoryItemLabels: ["2ef43603-b32e-40ef-9997-d0b4b1d101ac"],
         items: ["dc1c586a-07bd-4698-b910-7313152dc858"],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         parentConfig: "Default"
      },
      {
         id: "0d351a0f-65da-47fc-adcc-da42310ef182",
         parentItem: "f0d54074-496f-4783-ae30-101e35612e94",
         collection: "qualification_set_control",
         directoryItemLabels: ["127a6118-d48b-4b78-b5ec-f73d4c5068ce"],
         items: ["8f02654c-260a-44f1-a9b9-5dc6610a3749"],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         parentConfig: "Default"
      },
      {
         id: "5798ace6-e139-4780-a719-208956d7a7a7",
         parentItem: "f0d54074-496f-4783-ae30-101e35612e94",
         collection: "activity_control",
         directoryItemLabels: ["a6f7cdd1-156e-4757-bafc-86edf89b36aa"],
         items: ["eefd6c74-6839-46f5-935f-34579be26925"],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         parentConfig: "Default"
      },
      {
         id: "dc1c586a-07bd-4698-b910-7313152dc858",
         parentItem: "70e1a935-ac8b-4f5e-9f95-c42ca6222761",
         collection: "meeting",
         query: {
            group: "or",
            rules: [{ field: "meetingStatus", type: "select", operator: "in", select: "one", value: ["incomplete", "in_progress", "scheduled", "draft", "proposed", "under_review"] }]
         },
         directoryItemLabels: ["9b3cc738-185b-4f6e-a74d-e44261c8cf22"],
         items: [],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         parentConfig: "Default"
      },
      {
         id: "8f02654c-260a-44f1-a9b9-5dc6610a3749",
         parentItem: "0d351a0f-65da-47fc-adcc-da42310ef182",
         collection: "qualification_set_control",
         query: { group: "and", rules: [{ field: "status", type: "select", operator: "not_in", select: "one", value: ["closed", "archived"] }] },
         directoryItemLabels: ["7682c2c7-3055-4026-a14f-4e79004607e6"],
         items: ["fb7e3147-8e40-4b8b-a034-fc642c6eb73e"],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         parentConfig: "Default"
      },
      {
         id: "fb7e3147-8e40-4b8b-a034-fc642c6eb73e",
         parentItem: "8f02654c-260a-44f1-a9b9-5dc6610a3749",
         collection: "qualification_set_version",
         query: { group: "and", rules: [{ field: "status", type: "select", operator: "not_in", select: "one", value: ["closed", "archived"] }] },
         directoryItemLabels: ["d1c6036c-10a4-42e7-bce9-d2ef447c96d9", "096dcad0-1bbb-4851-8b02-f7b141ef935e", "a15bd6f5-3ae8-47ae-a2a9-333b87d0afab"],
         items: [],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         parentConfig: "Default"
      },
      {
         id: "eefd6c74-6839-46f5-935f-34579be26925",
         parentItem: "5798ace6-e139-4780-a719-208956d7a7a7",
         collection: "activity_control",
         query: { group: "and", rules: [{ field: "status", type: "select", operator: "not_in", select: "one", value: ["closed", "archived"] }] },
         directoryItemLabels: ["942b1356-bad8-41fc-bccb-04267b2e8246"],
         items: ["20641d76-6f29-41a2-afca-6806c1401af6"],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         parentConfig: "Default"
      },
      {
         id: "20641d76-6f29-41a2-afca-6806c1401af6",
         parentItem: "eefd6c74-6839-46f5-935f-34579be26925",
         collection: "activity_version",
         directoryItemLabels: ["8fc68ed5-0648-45bb-b022-4958467e2626"],
         items: ["65983ce7-3a1c-4f69-ad56-df9f102f9da5"],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         parentConfig: "Default"
      },
      {
         id: "65983ce7-3a1c-4f69-ad56-df9f102f9da5",
         parentItem: "20641d76-6f29-41a2-afca-6806c1401af6",
         collection: "activity_version",
         query: { group: "and", rules: [{ field: "status", type: "select", operator: "not_in", select: "one", value: ["closed", "archived"] }] },
         directoryItemLabels: ["2d3dbf16-f819-4a73-98dd-91d032f9475e"],
         items: ["ed403d3b-3f7a-476a-9a60-8c59ef53d5d7", "9c27b50e-b064-43f4-9d74-c20848fd2410"],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         parentConfig: "Default"
      },
      {
         id: "ed403d3b-3f7a-476a-9a60-8c59ef53d5d7",
         parentItem: "65983ce7-3a1c-4f69-ad56-df9f102f9da5",
         collection: "space",
         query: { group: "or", rules: [] },
         directoryItemLabels: ["3ef21936-d081-4898-bcba-c0c455e3ce1e"],
         items: [],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         parentConfig: "Default"
      },
      {
         id: "9c27b50e-b064-43f4-9d74-c20848fd2410",
         parentItem: "65983ce7-3a1c-4f69-ad56-df9f102f9da5",
         collection: "activity_version",
         directoryItemLabels: ["da6123b9-ccaf-4e63-aa7c-c5cfc9312e15"],
         items: ["8a5dbafd-9b01-4acb-8c8f-f97d465acc9f"],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         parentConfig: "Default"
      },
      {
         id: "8a5dbafd-9b01-4acb-8c8f-f97d465acc9f",
         parentItem: "9c27b50e-b064-43f4-9d74-c20848fd2410",
         collection: "activity_attempt",
         query: { group: "and", rules: [{ field: "status", type: "select", operator: "not_in", select: "one", value: ["closed", "archived", "confirmed_complete"] }] },
         directoryItemLabels: ["35088001-c0f0-48c0-ae6a-ce94571618e2"],
         items: ["1cd60b7d-8842-4fbf-b030-e68316c6c652"],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         parentConfig: "Default"
      },
      {
         id: "1cd60b7d-8842-4fbf-b030-e68316c6c652",
         parentItem: "8a5dbafd-9b01-4acb-8c8f-f97d465acc9f",
         collection: "space",
         query: { group: "or", rules: [] },
         directoryItemLabels: ["745d2458-001b-4133-8dc4-b157c73d3b83"],
         items: [],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         parentConfig: "Default"
      },
      {
         id: "f0d54074-496f-4783-ae30-101e35612e94",
         parentItem: null,
         collection: "campaign",
         directoryItemLabels: ["bfd08e37-7155-4265-97a7-b010148a32f2"],
         items: ["0f66ceef-4b9b-4513-9111-509467670fce", "70e1a935-ac8b-4f5e-9f95-c42ca6222761", "0d351a0f-65da-47fc-adcc-da42310ef182", "5798ace6-e139-4780-a719-208956d7a7a7"],
         isOpenOnPreferencesPage: false,
         considerContext: true,
         query: { group: "or", rules: [{ field: "roles", type: "select", operator: "in", select: "one", value: ["host", "campaign_manager", "owner", "connection"] }] },
         parentConfig: "Default"
      }
   ],
   labels: [
      { id: "bfd08e37-7155-4265-97a7-b010148a32f2", key: "campaign_name", directoryItemId: "f0d54074-496f-4783-ae30-101e35612e94", sort: 1, keyofParent: false, parentConfig: "Default" },
      { id: "369df7bd-605b-4753-8d43-57966c0a3e49", label: "Editions", directoryItemId: "0f66ceef-4b9b-4513-9111-509467670fce", sort: 1, keyofParent: false, parentConfig: "Default" },
      { id: "2ef43603-b32e-40ef-9997-d0b4b1d101ac", label: "Meetings", directoryItemId: "70e1a935-ac8b-4f5e-9f95-c42ca6222761", sort: 1, keyofParent: false, parentConfig: "Default" },
      { id: "127a6118-d48b-4b78-b5ec-f73d4c5068ce", label: "Skillsets", directoryItemId: "0d351a0f-65da-47fc-adcc-da42310ef182", sort: 1, keyofParent: false, parentConfig: "Default" },
      { id: "a6f7cdd1-156e-4757-bafc-86edf89b36aa", label: "Teams ", directoryItemId: "5798ace6-e139-4780-a719-208956d7a7a7", sort: 1, keyofParent: false, parentConfig: "Default" },
      { id: "9b3cc738-185b-4f6e-a74d-e44261c8cf22", key: "meeting_title", directoryItemId: "dc1c586a-07bd-4698-b910-7313152dc858", sort: 1, keyofParent: false, parentConfig: "Default" },
      {
         id: "7682c2c7-3055-4026-a14f-4e79004607e6",
         key: "qualification_set_control_title",
         directoryItemId: "8f02654c-260a-44f1-a9b9-5dc6610a3749",
         sort: 1,
         keyofParent: false,
         parentConfig: "Default"
      },
      {
         id: "d1c6036c-10a4-42e7-bce9-d2ef447c96d9",
         key: "qualification_set_control_title",
         directoryItemId: "fb7e3147-8e40-4b8b-a034-fc642c6eb73e",
         sort: 1,
         keyofParent: true,
         parentConfig: "Default"
      },
      { id: "942b1356-bad8-41fc-bccb-04267b2e8246", key: "activity_control_title", directoryItemId: "eefd6c74-6839-46f5-935f-34579be26925", sort: 1, keyofParent: false, parentConfig: "Default" },
      { id: "2d3dbf16-f819-4a73-98dd-91d032f9475e", key: "activity_version_title", directoryItemId: "65983ce7-3a1c-4f69-ad56-df9f102f9da5", sort: 1, keyofParent: false, parentConfig: "Default" },
      { id: "3ef21936-d081-4898-bcba-c0c455e3ce1e", label: "Template Documents", directoryItemId: "ed403d3b-3f7a-476a-9a60-8c59ef53d5d7", sort: 1, keyofParent: false, parentConfig: "Default" },
      { id: "da6123b9-ccaf-4e63-aa7c-c5cfc9312e15", label: "Attempts", directoryItemId: "9c27b50e-b064-43f4-9d74-c20848fd2410", sort: 1, keyofParent: false, parentConfig: "Default" },
      { id: "35088001-c0f0-48c0-ae6a-ce94571618e2", key: "activity_attempt_title", directoryItemId: "8a5dbafd-9b01-4acb-8c8f-f97d465acc9f", sort: 1, keyofParent: false, parentConfig: "Default" },
      { id: "745d2458-001b-4133-8dc4-b157c73d3b83", label: "Attempt Documents", directoryItemId: "1cd60b7d-8842-4fbf-b030-e68316c6c652", sort: 1, keyofParent: false, parentConfig: "Default" },
      { id: "8fc68ed5-0648-45bb-b022-4958467e2626", label: "Templates", directoryItemId: "20641d76-6f29-41a2-afca-6806c1401af6", sort: 1, keyofParent: false, parentConfig: "Default" },
      {
         id: "7c71799d-9319-4a0f-b60c-bdf7b62ed522",
         key: "campaign_edition_publish_date_time",
         directoryItemId: "2921a5e7-ebaf-462f-9b48-d3afe24c3958",
         sort: 1,
         keyofParent: false,
         parentConfig: "Default"
      },
      { id: "096dcad0-1bbb-4851-8b02-f7b141ef935e", label: "  Path #", directoryItemId: "fb7e3147-8e40-4b8b-a034-fc642c6eb73e", sort: 2, keyofParent: false, parentConfig: "Default" },
      { id: "a15bd6f5-3ae8-47ae-a2a9-333b87d0afab", key: "sort", directoryItemId: "fb7e3147-8e40-4b8b-a034-fc642c6eb73e", sort: 3, keyofParent: false, parentConfig: "Default" }
   ]
};
