import { VariantProps, cva } from "class-variance-authority";
import React from "react";

interface ParagraphProps extends React.DetailsHTMLAttributes<HTMLParagraphElement>, VariantProps<typeof paragraphClasses> {
   label: string | string[] | undefined | null;
   bgColor?: "white" | "gray" | "black" | "red" | "green" | "blue" | "amber" | "inherit";
   variant?: "solid" | "outline";
   className?: string;
   testId?: string;
}

const paragraphClasses = cva("", {
   variants: {
      bgColor: {
         inherit: "bg-inherit",
         white: "bg-white text-black",
         gray: "bg-gray-500 text-white",
         black: "bg-black text-white",
         red: "bg-red-400 text-white",
         green: "bg-green-400 text-white",
         blue: "bg-blue-500 text-white",
         amber: "bg-amber-400 text-white"
      },
      textColor: {
         inherit: "text-inherit",
         white: "text-white",
         gray: "text-gray-500",
         black: "text-black",
         red: "text-red-400",
         green: "text-green-400",
         blue: "text-blue-500",
         amber: "text-amber-400"
      },
      variant: {
         solid: "",
         outline: "border"
      },
      wrap: {
         true: "",
         false: "whitespace-nowrap"
      },
      rounded: {
         true: "rounded-md drop-shadow-md p-2",
         false: ""
      }
   },
   defaultVariants: {
      bgColor: "inherit",
      variant: "solid",
      textColor: "black"
   }
});

// const _Paragraph: React.FC<ParagraphProps> = ({ label, color = "inherit", variant = "solid", className = "", testId, wrap = true, rounded = false }) => {
//    const combinedClasses = paragraphClasses({ color, variant, wrap, rounded }) + ` ${className}`;

//    return (
//       <p id={`${testId}`} data-testid={`${testId}`} className={combinedClasses}>
//          {label}
//       </p>
//    );
// };

function _Paragraph({ label, bgColor = "inherit", variant = "solid", className = "", testId, wrap = true, rounded = false, textColor }: ParagraphProps) {
   const combinedClasses = paragraphClasses({ bgColor, variant, wrap, rounded, textColor }) + ` ${className}`;

   return (
      <p id={`${testId}`} data-testid={`${testId}`} className={combinedClasses}>
         {label}
      </p>
   );
}
export default _Paragraph;
