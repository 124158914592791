import { useWindowSize } from "usehooks-ts";

/* 

useBodySizeContext (bodySizeWidth, bodySizeHeight)

useWindowSize (windowWidth, windowHeight)

Fix height w/goal of 25vh = 400px

Width = 100% of body size

Will Directus stretch images, or only send the max size of image?

*/
export default function useBodySizeContext({ bodyHeight, bodyWidth }: { bodyWidth: number | undefined; bodyHeight: number | undefined }) {
   const { width: windowWidth, height: windowHeight } = useWindowSize();
   // const {elementWidth, elementHeight} = useElementSize(bodySizeWidth, bodySizeHeight, windowWidth, windowHeight, fullScreenElement)

   return {
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      bodyWidth: bodyWidth,
      bodyHeight: bodyHeight
   };
}
