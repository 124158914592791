import _Button from "app/_components_v2/inputs/_Button";
import _Stack from "app/_components_v2/layout/_Stack";
import Flag from "./Flag";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";

export default function Footer(props: { mark?: boolean; flag?: boolean }) {
   const { mark, flag } = props;

   return (
      <_DivUNSAFE className="mt-16 flex w-[100%] flex-col place-items-center place-self-center justify-self-center border-t-2 p-4">
         <_Stack flexBehavior={"row"} alignment={"center"} gap={"lg"} wrap>
            <_Button testId="" variant={"transparent"} href="/">
               Home
            </_Button>
            <_Button testId="" variant={"transparent"} href={`/faq`}>
               FAQ
            </_Button>
            <_Button testId="" variant={"transparent"} href={`/legal/terms`}>
               Terms and Conditions
            </_Button>
            <_Button testId="" variant={"transparent"} href={`/legal/privacy`}>
               Privacy Policy
            </_Button>
            <_Button testId="" variant={"transparent"} href={`/about`}>
               About
            </_Button>
         </_Stack>

         {mark && <Flag flag={flag} />}
      </_DivUNSAFE>
   );
}
