"use client";
import CropLandscapeIcon from "@mui/icons-material/CropLandscape";
import CropPortraitIcon from "@mui/icons-material/CropPortrait";
import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined";
import _Icon from "app/_components_v2/data-display/_Icon";
import _Button from "app/_components_v2/inputs/_Button";
import _Select from "app/_components_v2/inputs/_Select";
import _Stack from "app/_components_v2/layout/_Stack";
import { FloatingNav } from "app/_components_v2/layout/FloatingNav";
import ILog from "app/_lib/global/Log";
import { Roles } from "app/_lib/global/Roles";
import { PA } from "app/_types/PATypes";
import { VariantProps, cva } from "class-variance-authority";
import React, { createContext, useContext, useMemo } from "react";
import { useLocalStorage, useResizeObserver } from "usehooks-ts";
import { AuthStatusContext } from "../(authentication)/_contexts/AuthenticatedSessionProvider";
import SignIn from "../(authentication)/sign-in/page";
import { BodySizeContext } from "../_contexts/BodySizeContext";
import useBodySizeContext from "../_hooks/useBodySizeContext";
import useAllRoleConditionals, { AcronymType, UseAllRoleConditionalsProps } from "../workspaces/[campaign_id]/_hooks/useAllRoleConditionals";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";

interface BodyProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof bodyClasses> {
   children: React.ReactNode;
   supportWidget?: boolean;
   authenticationRequired?: boolean;
   roleSelectionState?: ReturnType<typeof useRoleSelection> | undefined;
   includeDefaultRoleSelector?: boolean;
}
const bodyClasses = cva("w-full flex grow ", {
   variants: {
      mainVariant: {
         primary: "flex flex-col overflow-y-auto gap-6 place-items-center",
         none: "overflow-y-auto "
      },
      contentVariant: {
         primary: "flex flex-col gap-6 ", // width of EditorContent
         none: "flex flex-col grow "
      },
      maxWidth: {
         none: "w-full",
         "1200px": "max-w-[1056px]"
      },
      margin: {
         none: "",
         4: "m-4"
      },
      marginTop: {
         none: "",
         4: "mt-4",
         16: "mt-16"
      }
   }
});

export const Body: React.FC<BodyProps> = ({
   children,
   supportWidget = false,
   margin = "none",
   maxWidth = "1200px",
   marginTop = 16,
   authenticationRequired = false,
   roleSelectionState,
   mainVariant = "primary",
   contentVariant = "primary",
   includeDefaultRoleSelector = true
}) => {
   const bodyRef = React.useRef<HTMLDivElement>(null);
   const { status } = useContext(AuthStatusContext);
   const { width, height } = useResizeObserver({ ref: bodyRef });

   const { bodyHeight, bodyWidth, windowHeight, windowWidth } = useBodySizeContext({ bodyWidth: width, bodyHeight: height });
   const [isPortrait, setIsPortrait] = useLocalStorage("orientation", true);
   const contentStyles = bodyClasses({ margin, maxWidth, marginTop, contentVariant }) + ` ${contentVariant !== "none" ? (isPortrait ? "max-w-[816px]" : "max-w-[1056px]") : ""}`;
   const mainStyles = bodyClasses({ mainVariant });
   return (
      <BodySizeContext.Provider value={{ bodyHeight, bodyWidth, windowHeight, windowWidth }}>
         <div ref={bodyRef} id="body" role="main" className={mainStyles}>
            {authenticationRequired === true && status !== "authenticated" ? (
               <SignIn />
            ) : (
               <React.Fragment>
                  {includeDefaultRoleSelector && roleSelectionState && <RoleSelector roleSelectionState={roleSelectionState} />}
                  <div id="body-role-selector" className={contentStyles}>
                     {roleSelectionState?.claimIrrelevance && roleSelectionState?.claimRelevance ? (
                        <RoleSelectorContext.Provider
                           value={{
                              role: roleSelectionState?.selectedRole,
                              claimIrrelevance: roleSelectionState?.claimIrrelevance,
                              claimRelevance: roleSelectionState?.claimRelevance,
                              definedRoles: roleSelectionState?.options
                           }}
                        >
                           {children}
                        </RoleSelectorContext.Provider>
                     ) : (
                        children
                     )}
                  </div>
               </React.Fragment>
            )}
         </div>
      </BodySizeContext.Provider>
   );
};
interface UseRoleSelectionProps extends UseAllRoleConditionalsProps {}
export function useRoleSelection(props: UseRoleSelectionProps) {
   const { include, order, ...rest } = props;
   const [selectedRole, setSelectedRole] = React.useState<PA.RoleQueries | undefined>(undefined);
   const [irreleventClaims, setIrreleventClaims] = React.useState<AcronymType[]>([]);
   const [relevantClaims, setRelevantClaims] = React.useState<AcronymType[]>([]);

   function claimRelevance(role: AcronymType) {
      if (!relevantClaims.includes(role)) {
         setRelevantClaims((prev) => [...prev, role]);
      }
   }
   function claimIrrelevance(role: AcronymType) {
      if (!irreleventClaims.includes(role)) {
         setIrreleventClaims((prev) => [...prev, role]);
      }
   }

   const { finalInclude, finalOrder } = useMemo(() => {
      if (!irreleventClaims.length) return { finalInclude: include, finalOrder: order };
      let finalRemove = irreleventClaims;
      if (relevantClaims.length) finalRemove = finalRemove.filter((role) => !relevantClaims.includes(role));
      const finalInclude = include?.filter((role) => !finalRemove.includes(role));
      const finalOrder = order?.filter((role) => !finalRemove.includes(role));
      return { finalInclude, finalOrder };
   }, [include, order, relevantClaims, irreleventClaims]);
   const { roles } = useAllRoleConditionals({ include: finalInclude, order: finalOrder, ...rest });

   ILog.v("useRoleSelection", { props, roles });
   if (roles.length > 0) {
      return { selectedRole, setSelectedRole, options: roles, claimIrrelevance, claimRelevance };
   } else {
      return undefined;
   }
}

function RoleSelector({ roleSelectionState }: { roleSelectionState: ReturnType<typeof useRoleSelection> }) {
   const [isPortrait, setIsPortrait] = useLocalStorage("orientation", true);
   const definedOptions = roleSelectionState?.options;
   if (!definedOptions) return null;
   return (
      <_DivUNSAFE className="flex m-2 place-self-end">
         <FloatingNav
            alignment="end"
            placement="top-end"
            allowedPlacements={["left-start"]}
            referenceChild={
               <_Button id="open-perspective-select-button" testId="open-perspective-select-button" aria-label="Open Perspective Select" onButtonClick={() => {}}>
                  <_Icon variant={"primary"}>{({ iconClassname }) => <PageviewOutlinedIcon className={iconClassname} />}</_Icon>
               </_Button>
            }
            floatingChild={({ setHovered }) => (
               <_Stack>
                  <_Select
                     focusOverride
                     id={`role-view-select`}
                     key={`role-view-select`}
                     variant="outlined"
                     displayEmpty
                     renderValue={(value) => {
                        ILog.v("RoleSelector_renderValue", { value });
                        if (value === "" || value === undefined || value === null) {
                           return "All Relevant";
                        } else if (!!value) {
                           const type = value as string;
                           return Roles[type as PA.RoleQueries["type"]];
                        }
                     }}
                     placeholder={undefined}
                     label={"Perspective:"}
                     value={roleSelectionState?.selectedRole?.type || ""}
                     testId={`role-view-select`}
                     options={[
                        { label: "All Relevant", value: undefined },
                        ...definedOptions.map((op) => {
                           return { label: Roles[op.type], value: op.type };
                        })
                     ]}
                     onChange={(e) => {
                        const selected = definedOptions.find((op) => op.type === e.target.value);
                        if (selected) {
                           roleSelectionState.setSelectedRole(selected);
                        } else {
                           roleSelectionState.setSelectedRole(undefined);
                        }
                     }}
                  />
                  <_Button
                     rounded
                     testId="toggle-orientation-button"
                     active={false}
                     variant={`transparent`}
                     onButtonClick={() => {
                        setIsPortrait(!isPortrait);
                     }}
                  >
                     Orientation:
                     {isPortrait ? (
                        <_Icon variant={"primary"}>{({ iconClassname }) => <CropPortraitIcon className={iconClassname} />}</_Icon>
                     ) : (
                        <_Icon variant={"primary"}>{({ iconClassname }) => <CropLandscapeIcon className={iconClassname} />}</_Icon>
                     )}
                  </_Button>
               </_Stack>
            )}
         />
      </_DivUNSAFE>
   );
}
interface RoleSelectorContextProps {
   role: PA.RoleQueries | undefined;
   claimRelevance: (role: AcronymType) => void;
   claimIrrelevance: (role: AcronymType) => void;
   definedRoles: PA.RoleQueries[];
}

export const RoleSelectorContext = createContext<RoleSelectorContextProps>({
   role: undefined,
   claimRelevance: (role) => {},
   claimIrrelevance: (role) => {},
   definedRoles: []
});

export function useRoleSelectorContext() {
   const { role, claimIrrelevance, claimRelevance, definedRoles } = React.useContext(RoleSelectorContext);

   return { role, claimIrrelevance, claimRelevance, definedRoles };
}
