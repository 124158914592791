"use client";
import CloseIcon from "@mui/icons-material/Close";
import { ReactNode, useState } from "react";
import _Paragraph from "../data-display/Paragraph";

function Info(props: { dismissable?: boolean; message: string; onDismiss?: any }) {
   const { message, dismissable, onDismiss } = props;
   const [show, setShow] = useState(true);

   const handleDismiss = () => {
      setShow(false);
      if (onDismiss) {
         onDismiss();
      }
   };
   if (show) {
      return (
         <div className="flex flex-row gap-3 p-3 place-content-center place-items-center">
            <Info.Message message={message} />
            {dismissable && <Info.Dismiss handleDismiss={handleDismiss} />}
         </div>
      );
   }
   return null;
}

function Dismiss(props: { handleDismiss: () => void }) {
   const { handleDismiss } = props;

   return (
      <button className="" onMouseDown={handleDismiss}>
         <CloseIcon className="w-6 h-6 place-self-center" />
      </button>
   );
}

function Message(props: { message: string }) {
   const { message } = props;

   return <_Paragraph label={message} />;
}

function Banner(props: { children: ReactNode }) {
   const { children } = props;

   return <div className="flex h-[100%] w-[100%] flex-col text-xl rounded-md place-content-center place-items-center gap-3">{children}</div>;
}

Info.Message = Message;
Info.Dismiss = Dismiss;
Info.Banner = Banner;

export default Info;
