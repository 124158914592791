import React from "react";
import Squares from "react-activity/dist/Squares";
import "react-activity/dist/Squares.css";
interface LoadingProps {
   className?: string;
}

const LoadingSquares: React.FC<LoadingProps> = ({ className = "" }) => {
   return <Squares color="#727981" size={16} speed={0.5} animating={true} className={`flex place-self-center flex-nowrap min-w-fit ${className}`} />;
};

export default LoadingSquares;
