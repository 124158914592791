import calculateDateTime from "app/_helpers/calculateDateTime";
import { useMemo } from "react";

export default function useDateTime(timestamp: number | undefined) {
   const humanReadableDate = useMemo(() => {
      if (timestamp) {
         return calculateDateTime(timestamp, true);
      }
   }, [timestamp]);

   return { humanReadableDate };
}
