import getRemSize from "app/_helpers/getRemSize";
import { useMemo } from "react";

export type RemSizeLevel = "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
export function useRemSize({ multiplier, level }: { multiplier: number | undefined; level: RemSizeLevel | undefined }) {
   return useMemo(() => {
      return { fontSize: getRemSize() * parseLevelMultiplier({ multiplier, level }), fontClasses: levelToTailwindClass({ level }) };
   }, [window.innerWidth, window.innerHeight, multiplier]);
}

function parseLevelMultiplier({ level, multiplier }: { level: RemSizeLevel | undefined; multiplier: number | undefined }): number {
   const modifier = 0.54;
   if (multiplier) {
      return multiplier;
   } else if (level === "p") {
      return 1 * modifier; // 1
   } else if (level === "h1") {
      return 2.25 * modifier; // 2.25
   } else if (level === "h2") {
      return 1.875 * modifier; // 1.875
   } else if (level === "h3") {
      return 1.5 * modifier; // 1.5
   } else if (level === "h4") {
      return 1.25 * modifier; // 1.25
   } else if (level === "h5") {
      return 1.125 * modifier; //  1.125
   } else if (level === "h6") {
      return 1 * modifier; // 1
   }
   return 1;
}

export function levelToTailwindClass({ level }: { level: RemSizeLevel | undefined }): string {
   if (level === "p") {
      return "text-base!";
   } else if (level === "h1") {
      return "text-4xl! font-bold!";
   } else if (level === "h2") {
      return "text-3xl! font-semibold!";
   } else if (level === "h3") {
      return "text-2xl! font-semibold!";
   } else if (level === "h4") {
      return "text-xl! font-semibold!";
   } else if (level === "h5") {
      return "text-lg! font-semibold!";
   } else if (level === "h6") {
      return "text-base! font-medium!";
   }
   return "";
}
