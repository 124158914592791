import { AppStore } from "app/_contexts/ReduxProvider";
import ILog from "app/_lib/global/Log";
import { selectAllDirectoryConfigurations } from "app/_services/redux/slices/directoryConfiguration";
import { directoryItemActions, selectAllDirectoryItems } from "app/_services/redux/slices/directoryItem";
import { directoryItemLabelActions, selectAllDirectoryItemLabels } from "app/_services/redux/slices/directoryItemLabel";
import { M } from "app/_types/Schema";

export default function cleanupDirectoryState({ store }: { store: AppStore }) {
   const state = store.getState();
   const configs = selectAllDirectoryConfigurations(state);
   const items = selectAllDirectoryItems(state);
   const labels = selectAllDirectoryItemLabels(state);

   const toplevelItems = items.filter((item) => !item.parentItem || !configs.find((config) => config.items.includes(item.id)));
   let orphanedItems = [] as M.DirectoryItem[];

   items.forEach((item) => {
      if (!item.parentConfig) {
         orphanedItems.push(item);
      } else if (item.parentItem && !items.find((i) => i.id === item.parentItem)) {
         orphanedItems.push(item);
      } else if (!item.parentItem && !configs.find((config) => config.items.includes(item.id))) {
         orphanedItems.push(item);
      }
   });
   let orphanedLabels = [] as M.DirectoryItemLabel[];
   labels.forEach((label) => {
      if (!label.parentConfig) {
         orphanedLabels.push(label);
      } else if (!items.find((item) => item.directoryItemLabels.includes(label.id))) {
         orphanedLabels.push(label);
      }
   });
   if (orphanedItems.length > 0) {
      store.dispatch(directoryItemActions.removeDirectoryItems(orphanedItems.map((item) => item.id)));
   }
   if (orphanedLabels.length > 0) {
      store.dispatch(directoryItemLabelActions.removeDirectoryItemLabels(orphanedLabels.map((label) => label.id)));
   }

   // labels.filter((label) => !items.find((item) => item.directoryItemLabels.includes(label.id)));

   ILog.v("cleanupDirectoryState", { toplevelItems, orphanedItems, orphanedLabels, configs, items, labels });
}

// function parseDefaultAndCustomConfigurations() {
//    const state = store.getState();
//    const configs = selectAllDirectoryConfigurations(state);
//    const items = selectAllDirectoryItems(state);
//    const labels = selectAllDirectoryItemLabels(state);
//    const defaultConfig = configs.find((c) => c.name === "Default" || c.name === "default");

//    return {
//       custom: { configs: [], items: [], labels: [] },
//       default: { configs: [], items: [], labels: [] }
//    };
// }
