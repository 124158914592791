import { TextField, TextFieldProps } from "@mui/material";
import { useDebouncedCallback } from "app/(platform)/_hooks/useDebounceCallback";
import { RemSizeLevel, useRemSize } from "app/_hooks/useRemSize";
import ILog from "app/_lib/global/Log";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
// These inputs aren't compatible with react-hook-form
function LeanInput() {
   return null;
}
export type ITextProps = {
   defaultValue?: string | null | undefined;
   value?: string | null | undefined;
   testId: string;
   debounceInterval?: number;
   focus?: boolean;
   onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
} & TextFieldProps;
//https://mui.com/material-ui/react-text-field/#shrink
function Text({ label, defaultValue, name, value, onChange = () => {}, testId, inputProps, debounceInterval = 0, focus = false, onBlur, ...rest }: ITextProps) {
   const [localValue, setLocalValue] = useState(value || defaultValue || "");
   const inputRef = useRef<HTMLInputElement>(null);

   useEffect(() => {
      if (focus && inputRef.current) {
         ILog.v("Text focus", { inputRef: inputRef.current });
         inputRef.current?.focus();
      }
   }, [focus, inputRef.current]);

   const debouncedChange = useDebouncedCallback(onChange, debounceInterval);

   return (
      <TextField
         inputRef={inputRef}
         InputLabelProps={{ shrink: !!defaultValue ? true : undefined }}
         label={label}
         // data-testid={testId}
         //className="w-[100%] border-0 hover:border rounded-md overflow-clip hover:border-slate-200 "
         className="rounded-md w-[100%] border-slate-200 active:border-slate-400 focus:border-slate-400 hover:border-slate-400 ring-slate-400 ring-offset-slate-400 active:ring-slate-400 focus:ring-slate-400 hover:ring-slate-400 active:ring-offset-slate-400 focus:ring-offset-slate-400 hover:ring-offset-slate-400"
         name={name}
         defaultValue={localValue}
         // value={localValue}
         onChange={debouncedChange}
         onKeyDown={(e) => {
            if (e.key === "Enter") {
               ILog.v("Enter");
               // e.target.dispatchEvent(new Event("blur-sm"));
               inputRef.current?.blur();
            }
            // else if (e.key === "Escape") {
            //    setLocalValue(value || defaultValue || "");
            //    setTimeout(() => {
            //       inputRef.current?.blur();
            //    }, 10);
            // }
         }}
         onBlur={(e) => {
            if (!e.target.value) return;
            setLocalValue(e.target.value);
            onBlur && onBlur(e);
         }}
         inputProps={{ "data-testid": testId, type: rest.type, ...inputProps }}
         {...rest}
      />
   );
}
/*

Enter inputs
onEnter, submit and blur
onEscape, submit and blur

onBlur, submit

read localValue instead of e.target.value

*/
function Text2({
   label,
   defaultValue,
   name,
   value,
   onChangeEvent,
   testId,
   inputProps,
   debounceInterval = 0,
   focus = false,
   onBlurEvent,
   ...rest
}: ITextProps & {
   onChangeEvent: (params: {
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
      localValue: string;
      setLocalValue: Dispatch<SetStateAction<string>>;
      setError: Dispatch<SetStateAction<string | null>>;
   }) => void;
   onSubmit?: (params: { localValue: string; setLocalValue: Dispatch<SetStateAction<string>>; setError: Dispatch<SetStateAction<string | null>> }) => void;
   onBlurEvent?: (params: {
      e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>;
      localValue: string;
      setLocalValue: Dispatch<SetStateAction<string>>;
      setError: Dispatch<SetStateAction<string | null>>;
   }) => void;
}) {
   const [localValue, setLocalValue] = useState(value || defaultValue || "");
   const [error, setError] = useState<string | null>(null);
   const inputRef = useRef<HTMLInputElement>(null);

   useEffect(() => {
      if (focus && inputRef.current) {
         ILog.v("Text focus", { inputRef: inputRef.current });
         inputRef.current?.focus();
      }
   }, [focus, inputRef.current]);

   useEffect(() => {
      setLocalValue(value || defaultValue || "");
   }, [value]);

   // const debouncedChange = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
   //    onChangeEvent({ e, localValue, setLocalValue });
   // }, debounceInterval);
   ILog.v("Text2", { value, defaultValue, localValue });
   return (
      <TextField
         inputRef={inputRef}
         InputLabelProps={{ shrink: !!defaultValue ? true : undefined }}
         label={label}
         className="rounded-md w-[100%] border-slate-200 active:border-slate-400 focus:border-slate-400 hover:border-slate-400 ring-slate-400 ring-offset-slate-400 active:ring-slate-400 focus:ring-slate-400 hover:ring-slate-400 active:ring-offset-slate-400 focus:ring-offset-slate-400 hover:ring-offset-slate-400"
         name={name}
         value={localValue}
         error={!!error}
         helperText={error}
         onChange={(e) => {
            ILog.v("Text2_onChange", { localValue, value, defaultValue, e, targetValue: e.target.value });
            onChangeEvent({ e, localValue, setLocalValue, setError });
         }}
         onKeyDown={(e) => {
            if (e.key === "Enter") {
               ILog.v("Enter");
               // e.target.dispatchEvent(new Event("blur-sm"));
               inputRef.current?.blur();
            } else if (e.key === "Escape") {
               // setLocalValue(defaultValue || value || "");
               inputRef.current?.blur();
            }
         }}
         onBlur={(e) => {
            onBlurEvent && onBlurEvent({ e, localValue, setLocalValue, setError });
         }}
         inputProps={{ "data-testid": testId, type: rest.type, ...inputProps }}
         {...rest}
      />
   );
}
const DEFAULT_INPUT_WIDTH = 30;
function Text3({
   label,
   defaultValue,
   name,
   value,
   onChangeEvent,
   testId,
   inputProps,
   debounceInterval = 0,
   focus = false,
   onBlurEvent,
   multiplier,
   level,
   fullWidth = false,
   containerQuery,
   ...rest
}: ITextProps & {
   onChangeEvent: (params: {
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
      localValue: string;
      setLocalValue: Dispatch<SetStateAction<string>>;
      setError: Dispatch<SetStateAction<string | null>>;
   }) => void;
   onSubmit?: (params: { localValue: string; setLocalValue: Dispatch<SetStateAction<string>>; setError: Dispatch<SetStateAction<string | null>> }) => void;
   onBlurEvent?: (params: {
      e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>;
      localValue: string;
      setLocalValue: Dispatch<SetStateAction<string>>;
      setError: Dispatch<SetStateAction<string | null>>;
   }) => void;
   multiplier?: number;
   level: RemSizeLevel;
   fullWidth?: boolean;
   containerQuery?: string;
}) {
   const [localValue, setLocalValue] = useState(value || defaultValue || "");
   const [error, setError] = useState<string | null>(null);
   const inputRef = useRef<HTMLInputElement>(null);

   useEffect(() => {
      if (focus && inputRef.current) {
         ILog.v("Text focus", { inputRef: inputRef.current });
         inputRef.current?.focus();
      }
   }, [focus, inputRef.current]);

   useEffect(() => {
      setLocalValue(value || defaultValue || "");
   }, [value]);
   const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);
   const { fontSize, fontClasses } = useRemSize({ multiplier, level });
   useEffect(() => {
      if (localValue.length * fontSize > DEFAULT_INPUT_WIDTH && !fullWidth) {
         setInputWidth((localValue.length + 1) * fontSize);
      } else {
         setInputWidth(DEFAULT_INPUT_WIDTH);
      }
   }, [localValue, fontSize, fullWidth]);

   // const debouncedChange = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
   //    onChangeEvent({ e, localValue, setLocalValue });
   // }, debounceInterval);
   ILog.v("Text3", { value, defaultValue, localValue, inputWidth, fontSize });
   // const classes = useNoStyles();
   return (
      <TextField
         id="lean-input-text3"
         inputRef={inputRef}
         InputLabelProps={{ shrink: !!defaultValue ? true : undefined, id: "lean-input-text3-label" }}
         FormHelperTextProps={{ id: "lean-input-text3-helper" }}
         label={label}
         className={`rounded border-0 p-0 m-0 outline-0 ring-0 hover:bg-slate-200 ${fontClasses} ${fullWidth ? "" : "max-w-[95%]!"}  ${containerQuery} `}
         variant="standard"
         name={name}
         value={localValue}
         error={!!error}
         helperText={error}
         onChange={(e) => {
            ILog.v("Text3_onChange", { localValue, value, defaultValue, e, targetValue: e.target.value });
            onChangeEvent({ e, localValue, setLocalValue, setError });
         }}
         onKeyDown={(e) => {
            if (e.key === "Enter") {
               ILog.v("Enter");
               // e.target.dispatchEvent(new Event("blur-sm"));
               inputRef.current?.blur();
            } else if (e.key === "Escape") {
               setLocalValue(defaultValue || value || "");
               setTimeout(() => {
                  inputRef.current?.blur();
               }, 1);
            }
         }}
         onBlur={(e) => {
            onBlurEvent && onBlurEvent({ e, localValue, setLocalValue, setError });
         }}
         fullWidth={fullWidth}
         // maxRows={3}
         multiline
         style={{ borderImageWidth: 0, borderColor: "white", border: 0, borderBottom: 0, width: fullWidth ? undefined : inputWidth }}
         InputProps={{
            id: "lean-input-text3-input-1",
            disableUnderline: true,
            fullWidth,
            style: { width: fullWidth ? undefined : inputWidth },
            className: ` hover:bg-slate-200 rounded ${fontClasses} p-0! ${fullWidth ? "" : "max-w-[95%]!"}  ${containerQuery} `
         }}
         inputProps={{
            "data-testid": testId,
            type: rest.type,
            ...inputProps,
            className: `hover:bg-slate-200 rounded ${fontClasses} p-0! ${fullWidth ? "" : "max-w-[95%]!"}  ${containerQuery} `,
            style: {
               padding: 0,
               margin: 0,
               outline: 0,
               border: 0,
               boxShadow: "none",
               // textWrap: "wrap",
               borderColor: "white",
               outlineColor: "white",
               outlineOffset: 0,
               outlineWidth: 0,
               outlineStyle: "none",
               borderBottom: 0,
               width: fullWidth ? undefined : inputWidth

               // width: "fit-content",
               // flexGrow: "unset",
               // boxSizing: "content-box"
            },
            id: "lean-input-text3-input-2"
         }}
         {...rest}
      />
   );
}
LeanInput.Text = Text;
LeanInput.Text2 = Text2;
LeanInput.Text3 = Text3;

export default LeanInput;
