import { FormControl, InputLabel, MenuItem, Select as MuiSelect, SelectProps } from "@mui/material";
import ILog from "app/_lib/global/Log";
import { VariantProps, cva } from "class-variance-authority";
import React from "react";
export interface Option {
   id?: string;
   testId?: string;
   value: string | undefined;
   label?: string;
   child?: React.ReactNode;
}
// extend MuiSelectProps

type ISelectProps = {
   options: Option[];
   label?: string;
   placeholder?: string | undefined;
   testId: string;
   className?: string;
   // Props for react-hook-form integration
   onChange?: (...event: any[]) => void;
   onBlur?: () => void;
   name?: string;
   value?: string | string[]; // string[] when multiple is true;
   multiple?: boolean;
   focusOverride?: boolean;
} & SelectProps &
   VariantProps<typeof selectClasses>;
const selectClasses = cva("flex flex-col h-fit ", {
   variants: {
      width: {
         fit: "w-fit",
         full: "w-full",
         64: "w-64"
      },
      margin: {
         none: "",
         4: "m-4"
      }
   },
   defaultVariants: {
      width: "fit",
      margin: "none"
   }
});
const _Select = React.forwardRef<HTMLSelectElement, ISelectProps>(
   ({ focusOverride, options, label, placeholder, testId, className, onChange, onBlur, name, value, width, margin, multiple, ...rest }, ref) => {
      const styles = selectClasses({ width, margin });
      return (
         <div key={`_Select-${testId}`} className={styles}>
            <FormControl focused={focusOverride} fullWidth>
               {label && (
                  <InputLabel focused={focusOverride} id={`${testId}-label`}>
                     {label}
                  </InputLabel>
               )}
               <MuiSelect
                  key={`_Select-${testId}-MuiSelect`}
                  labelId={`${testId}-label`}
                  name={name}
                  multiple={multiple}
                  ref={ref}
                  value={value}
                  label={label}
                  onChange={onChange}
                  onBlur={onBlur}
                  // className="block w-full mt-1 border-gray-300 shadow-xs form-select focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 "
                  data-testid={testId}
                  {...rest}
               >
                  {options.map((option) => {
                     if (option.label) {
                        return (
                           <MenuItem key={`MenuItem-${option.value}`} value={option.value} data-testid={option?.testId}>
                              {option.label}
                           </MenuItem>
                        );
                     } else if (option.child) {
                        return (
                           <MenuItem key={`MenuItem-${option.value}`} value={option.value} data-testid={option?.testId}>
                              {option.child}
                           </MenuItem>
                        );
                     } else {
                        ILog.w("Option must have a label or a child");
                        // throw new Error("Option must have a label or a child");
                     }
                  })}
               </MuiSelect>
            </FormControl>
         </div>
      );
   }
);

_Select.displayName = "_Select";

export default _Select;

// const Options = useMemo(() => {
//    return invitableUsers.map((userId) => {
//       return { child: <UserCard size="sm" userId={userId} key={`${userId}-select-attempt-contributor-option`} />, value: userId };
//    });
// }, [invitableUsers.length]);
{
   /* <_Select
            width={"full"}
            id={`invite-attempt-contributor-select`}
            key={`invite-attempt-contributor-select`}
            variant="standard"
            placeholder={"Invite a Contributor..."}
            label={"Invite a Contributor"}
            value={selectedUser}
            testId={`role-view-select`}
            options={Options}
            onChange={(e) => {
               const selected = invitableUsers.find((userId) => userId === e.target.value);
               // e.currentTarget.nodeValue
               if (selected) {
                  setSelectedUser(selected);
               } else {
                  setSelectedUser("");
               }
            }}
         /> */
}
