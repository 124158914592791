// Icon.tsx
import { cva, VariantProps } from "class-variance-authority";
import React from "react";

export interface IconProps extends VariantProps<typeof iconVariants> {
   children: ({ iconClassname }: { iconClassname: string }) => React.ReactNode;
   className?: string;
}

//hover:bg-slate-400
export const iconVariants = cva("flex items-center justify-center place-self-center overflow-hidden rounded-full font-semibold ", {
   variants: {
      variant: {
         light: "bg-slate-50 ",
         primary: " bg-slate-100",
         restorative: "bg-blue-400 hover:bg-blue-500 text-white",
         danger: "bg-red-300 hover:bg-red-400 text-white",
         notification: "bg-slate-800 text-slate-100",
         transparent: "hover:underline"
      },
      textColor: {
         red: "text-red-300",
         light: "text-slate-50",
         md: "text-slate-500",
         primary: "text-slate-900",
         green: "text-green-300",
         buttonPrimary: "text-slate-300"
      },
      rounded: {
         true: "rounded-full",
         false: "rounded-none"
      },
      isolate: {
         true: "isolate",
         false: ""
      },

      size: {
         none: "",
         xxs: "h-4 w-4 text-sm",
         xs: "h-5 w-5 text-base",
         sm: "h-6 w-6 text-lg",
         md: "h-10 w-10 text-xl",
         lg: "h-24 w-24 text-4xl"
         // sm: "text-xs px-2 py-1",
         // md: "text-sm px-4 py-2",
         // lg: "text-lg px-6 py-3"
      },
      disabled: {
         true: "opacity-50 cursor-not-allowed",
         false: "cursor-pointer",
         noPointer: ""
      },
      active: {
         true: "bg-slate-400",
         false: ""
      },
      margin: {
         none: "",
         4: "m-4"
      }
   },
   compoundVariants: [
      {
         variant: ["primary", "restorative", "danger"],
         class: ["h-10 w-10 text-xl"]
      }
   ],
   defaultVariants: {
      variant: "transparent",
      textColor: "primary",
      size: "md",
      rounded: true,
      disabled: false,
      active: false,
      isolate: false
   }
});

const _Icon: React.FC<IconProps> = ({ children: icon, size, margin, active, disabled, rounded, textColor, variant, isolate, className = "", ...rest }) => {
   const combinedClasses = iconVariants({ size, variant, active, disabled, margin, rounded, textColor, isolate }) + ` ${className}`;

   return (
      <span className={combinedClasses} {...rest}>
         {icon({ iconClassname: "w-full h-full" })}
      </span>
   );
};

export default _Icon;
